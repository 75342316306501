function AddPieceToLayoutZones(
  setupFieldChoices,
  copyOfZones,
  zoneIndex,
  layoutIndex,
  pieceType
) {
  if (pieceType === 'Straight LED Strip') {
    const numFields = copyOfZones[zoneIndex].layouts[layoutIndex].fields.length;

    copyOfZones[zoneIndex].layouts[layoutIndex].fields[
      numFields - 1
    ].showaddnextpiecebutton = false;
    copyOfZones[zoneIndex].layouts[layoutIndex].fields[
      numFields - 1
    ].showremovepiecebutton = false;
    copyOfZones[zoneIndex].layouts[layoutIndex].fields[
      numFields - 1
    ].showdeletelayoutbutton = false;

    let newPiece = {
      id:
        'zone-' +
        (zoneIndex + 1) +
        '-layout-' +
        (layoutIndex + 1) +
        '-component-' +
        (numFields + 1),
      current: true,
      title: 'Straight LED Strip',
      shorttitle: 'Straight LED Strip',
      length: 0,
      quantity: 0,
      instruction: 'Please specify a length for your next piece of LED Strip.',
      showaddnextpiecebutton: false,
      showdeletelayoutbutton: layoutIndex > 0 ? true : false,
      showendlayoutbutton: false,
      showfinishbutton: false,
      showremovepiecebutton: true,
      showaddlayoutbutton: false,
      showeditlayoutbutton: false,
      ledtype: '',
      iprating: 'IP20',
      wattage: '14.4W',
      colourtemp: '3000K',
      options: [
        {
          id: 'component-straight-high-IP20-3000K',
          selected: false,
          deselected: false,
          showinput: false,
          title: 'Straight LED Strip',
          description: 'Specify length up to 5000mm',
          sku: 'JC020067',
          length: 0,
          type: 'input',
          brightness: 'high',
          wattage: '14.4W',
          environment: 'dry',
          iprating: 'IP20',
          colourtemp: '3000K',
          imagepath: 'straight-white-144-IP20.jpg',
        },
      ],
    };

    if (setupFieldChoices.ledType === 'White') {
      // Set validation divisor - min cut length
      newPiece.options[0].validationdivisor = 50;

      if (setupFieldChoices.wattage === 4.8) {
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020059
          newPiece.SKU = 'JC020059';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-white-48-IP20.jpg';
          newPiece.options[0].price = 1.00;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020060
          newPiece.SKU = 'JC020060';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-white-48-IP20.jpg';
          newPiece.options[0].price = 1.00
        }
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020075
          newPiece.SKU = 'JC020075';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-white-48-IP65.jpg';
          newPiece.options[0].price = 1.20;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020076
          newPiece.SKU = 'JC020076';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-white-48-IP65.jpg';
          newPiece.options[0].price = 1.20;
        }
      }

      if (setupFieldChoices.wattage === 9.6) {
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020063
          newPiece.SKU = 'JC020063';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-white-96-IP20.jpg';
          newPiece.options[0].price = 2.60;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020064
          newPiece.SKU = 'JC020064';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-white-96-IP20.jpg';
          newPiece.options[0].price = 2.60;
        }
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020079
          newPiece.SKU = 'JC020079';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-white-96-IP65.jpg';
          newPiece.options[0].price = 2.80;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020080
          newPiece.SKU = 'JC020080';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-white-96-IP65.jpg';
          newPiece.options[0].price = 2.80;
        }
      }

      if (setupFieldChoices.wattage === 14.4) {
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020067
          newPiece.SKU = 'JC020067';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-white-144-IP20.jpg';
          newPiece.options[0].price = 3.10;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020068
          newPiece.SKU = 'JC020068';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-white-144-IP20.jpg';
          newPiece.options[0].price = 3.10;
        }
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020083
          newPiece.SKU = 'JC020083';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-white-144-IP65.jpg';
          newPiece.options[0].price = 3.4;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020084
          newPiece.SKU = 'JC020084';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-white-144-IP65.jpg';
          newPiece.options[0].price = 3.4;
        }
      }
    }

    if (setupFieldChoices.ledType === 'RGBW') {
      // Set validation divisor - min cut length
      newPiece.options[0].validationdivisor = 100;

      if (setupFieldChoices.wattage === 7.2) {
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020070
          newPiece.SKU = 'JC020070';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-RGBW-72-IP20.jpg';
          newPiece.options[0].price = 2.80;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020071
          newPiece.SKU = 'JC020071';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-RGBW-72-IP20.jpg';
          newPiece.options[0].price = 2.80;
        }
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020086
          newPiece.SKU = 'JC020086';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-RGBW-72-IP65.jpg';
          newPiece.options[0].price = 3.3;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020087
          newPiece.SKU = 'JC020087';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-RGBW-72-IP65.jpg';
          newPiece.options[0].price = 3.3;
        }
      }

      if (setupFieldChoices.wattage === 17.6) {
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020072
          newPiece.SKU = 'JC020072';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-RGBW-176-IP20.jpg';
          newPiece.options[0].price = 3.40;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP20'
        ) {
          // LED Strip SKU: JC020073
          newPiece.SKU = 'JC020073';
          newPiece.iprating = 'IP20';
          newPiece.options[0].imagepath = 'straight-RGBW-176-IP20.jpg';
          newPiece.options[0].price = 3.40;
        }
        if (
          setupFieldChoices.colourTemp === '3000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          // LED Strip SKU: JC020088
          newPiece.SKU = 'JC020088';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-RGBW-176-IP65.jpg';
          newPiece.options[0].price = 2.30;
        }
        if (
          setupFieldChoices.colourTemp === '4000K' &&
          setupFieldChoices.environment === 'IP65'
        ) {
          newPiece.SKU = 'JC020089';
          newPiece.iprating = 'IP65';
          newPiece.options[0].imagepath = 'straight-RGBW-176-IP65.jpg';
          newPiece.options[0].price = 2.30;
        }
      }
    }

    newPiece.options[0].validationlimit =
      copyOfZones[zoneIndex].layouts[layoutIndex].lengthRemaining;

    newPiece.ledtype = setupFieldChoices.ledType;
    newPiece.wattage = setupFieldChoices.wattage + 'W';
    newPiece.iprating = setupFieldChoices.environment;
    newPiece.colourtemp = setupFieldChoices.colourTemp;
    newPiece.options[0].description =
      setupFieldChoices.wattage +
      'W / ' +
      setupFieldChoices.colourTemp +
      ' / ' +
      setupFieldChoices.environment;

    copyOfZones[zoneIndex].layouts[layoutIndex].fields.push(newPiece);
  }
  if (pieceType === 'Connector') {
    const numFields = copyOfZones[zoneIndex].layouts[layoutIndex].fields.length;
    let newPiece = {};

    copyOfZones[zoneIndex].layouts[layoutIndex].fields[
      numFields - 1
    ].showaddnextpiecebutton = false;
    copyOfZones[zoneIndex].layouts[layoutIndex].fields[
      numFields - 1
    ].showremovepiecebutton = false;
    copyOfZones[zoneIndex].layouts[layoutIndex].fields[
      numFields - 1
    ].showendlayoutbutton = false;
    copyOfZones[zoneIndex].layouts[layoutIndex].fields[
      numFields - 1
    ].showdeletelayoutbutton = false;

    if (numFields === 1) {
      newPiece = {
        id:
          'zone-' +
          (zoneIndex + 1) +
          '-layout-' +
          (layoutIndex + 1) +
          '-component-' +
          (numFields + 1),
        current: true,
        title: 'Connector',
        shorttitle: 'DC Cable',
        length: 0,
        quantity: 0,
        showaddnextpiecebutton: false,
        showdeletelayoutbutton: layoutIndex > 0 ? true : false,
        showendlayoutbutton: false,
        showfinishbutton: false,
        showremovepiecebutton: true,
        showaddlayoutbutton: false,
        showeditlayoutbutton: false,
        options: [
          {
            id: 'component-dc-cable',
            selected: false,
            deselected: false,
            showinput: false,
            title: 'DC Cable',
            description: 'Specify length up to 10000mm',
            sku: 'JC020035',
            length: 0,
            validationdivisor: 50,
            validationlimit: 10000,
            type: 'input',
            imagepath: 'dc-cable.jpg',
          },
        ],
      };

      if (setupFieldChoices.ledType === 'White') {
        newPiece.SKU = 'JC020035';
        newPiece.options[0].price = 0.3;
        newPiece.instruction =
          'Please specify the length of DC Cable that comes directly from the driver. Click the DC Cable to enter the length.';
      }

      if (setupFieldChoices.ledType === 'RGBW') {
        newPiece.SKU = 'JC020092';
        newPiece.options[0].price = 0.7;
        newPiece.instruction =
          'Please specify the length of DC cable that comes directly from the controller. Click the DC Cable to enter the length.';
      }
    } else {
      newPiece = {
        id:
          'zone-' +
          (zoneIndex + 1) +
          '-layout-' +
          (layoutIndex + 1) +
          '-component-' +
          (numFields + 1),
        current: true,
        title: 'Connector',
        shorttitle: 'DC Cable',
        length: 0,
        quantity: 0,
        instruction:
          "Choose DC cable to lengthen your run between areas that don't require illumination, or specify a short piece of DC Cable to go around a corner.",
        showaddnextpiecebutton: false,
        showdeletelayoutbutton: layoutIndex > 0 ? true : false,
        showendlayoutbutton: false,
        showfinishbutton: false,
        showremovepiecebutton: true,
        showaddlayoutbutton: false,
        showeditlayoutbutton: false,
        options: [
          {
            id: 'component-dc-cable',
            selected: false,
            deselected: false,
            showinput: false,
            title: 'DC Cable',
            description: 'Specify length up to 10000mm',
            sku: 'JC020035',
            length: 0,
            validationdivisor: 50,
            validationlimit: 10000,
            type: 'input',
            imagepath: 'dc-cable.jpg',
          },
          {
            id: 'component-dc-cable-corner',
            selected: false,
            deselected: false,
            showinput: false,
            title: 'DC Cable Corner',
            description: 'Fixed 50mm length',
            sku: 'JC020035',
            length: 0,
            imagepath: 'dc-cable-corner-white.jpg',
          },
        ],
      };

      if (setupFieldChoices.ledType === 'White') {
        newPiece.options[1].imagepath = 'dc-cable-corner-white.jpg';
        newPiece.SKU = 'JC020035';
        newPiece.options[0].price = 0.3;
        newPiece.options[1].price = 0.3;
      }

      if (setupFieldChoices.ledType === 'RGBW') {
        newPiece.options[1].imagepath = 'dc-cable-corner-RGBW.jpg';
        newPiece.SKU = 'JC020092';
        newPiece.options[0].price = 0.7;
        newPiece.options[1].price = 0.7;
      }
    }

    copyOfZones[zoneIndex].layouts[layoutIndex].fields.push(newPiece);
  }

  return copyOfZones;
}

export default AddPieceToLayoutZones;
